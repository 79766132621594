<template>
  <div>
    <v-alert
      v-if="$store.getters['user/mealPlans'] == 0"
      type="warning"
      border="left"
      text
    >
      <span class="mr-2">
        This Nutrition professional has reached his/her maximum number of meal plans for the month. 
      </span>
    </v-alert>
    <v-card max-width="1000" class="mx-auto box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <div class="d-flex" style="gap: 0.5rem">
          <img :src="user?.customLogoUrl" height="70px" />
          <div>
            <div class="text-body-1">Personal Recipe eBook</div>
            <div class="text-caption">
              by <span>{{ user?.firstName }} {{ user?.lastName }}</span>
            </div>
          </div>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-overlay :value="!user">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div>
        <v-card-text v-if="user && !done" class="pa-0">
          <v-stepper class="elevation-0" v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">Goals</v-stepper-step>
              <v-divider></v-divider>
              <!-- <v-stepper-step :complete="step > 2" step="2"
                >Mealplan Details</v-stepper-step
              > -->
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">Tags</v-stepper-step>
              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items>
              <!-- Primary Goal -->
              <v-stepper-content step="1">
                <v-card class="mb-5 step-energy-dialog" data-step="3" flat>
                  <v-card-text>
                    <v-form ref="goalForm">
                      <v-row justify="center">
                        <v-col sm="12" md="8" lg="8" xl="8">
                          <v-text-field
                            v-model="data.email"
                            label="Client Email"
                            :rules="[rules.email, rules.required]"
                            outlined
                            type="email"
                            :loading="loading"
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            @blur="
                              ($event) => {
                                data.email && searchClient($event);
                              }
                            "
                            dense
                          ></v-text-field>

                          <v-text-field
                            v-model="data.client"
                            label="Full Name"
                            :loading="loading"
                            :disabled="loading || $store.getters['user/mealPlans'] == 0"
                            outlined
                            dense
                          ></v-text-field>

                          <v-text-field
                            v-model.number="data.age"
                            :rules="[rules.required]"
                            label="Age"
                            :loading="loading"
                            :disabled="loading || $store.getters['user/mealPlans'] == 0"
                            type="number"
                            outlined
                            dense
                          ></v-text-field>

                          <v-radio-group
                            :rules="[rules.required]"
                            v-model="data.gender"
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            label="Gender Assigned at Birth: "
                            class="mt-0"
                            dense
                            row
                          >
                            <v-radio label="Male" value="male"></v-radio>
                            <v-radio label="Female" value="female"></v-radio>
                          </v-radio-group>

                          <v-select
                            :label="plan ? 'Primary goal' : 'What is your primary goal?'"
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            :rules="[rules.required]"
                            v-model="data.goal"
                            item-value="value"
                            item-text="text"
                            :items="goals"
                            outlined
                            dense
                          ></v-select>

                          <v-text-field
                            :label="plan ? 'Height (in cm)' : 'Your Height (in cm)'"
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            v-model.number="data.height"
                            :rules="[rules.required]"
                            type="number"
                            suffix="cm"
                            outlined
                            dense
                          ></v-text-field>

                          <v-text-field
                            :label="plan ? 'Weight (in kg)' : 'Your Weight (in kg)'"
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            v-model.number="data.weight"
                            :rules="[rules.required]"
                            type="number"
                            suffix="kg"
                            outlined
                            dense
                          ></v-text-field>

                          <v-text-field
                            :label="
                              plan
                                ? 'Body Mass Index (BMI) is'
                                : 'Your Body Mass Index (BMI) is'
                            "
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            :value="Math.round(bmi)"
                            suffix="kg/m2"
                            outlined
                            readonly
                            dense
                          ></v-text-field>

                          <!-- <v-text-field
                            label="Your Goal Weight (in kg)"
                            v-model="data.goalWeight"
                            :rules="[rules.required]"
                            v-if="!plan"
                            suffix="kg"
                            outlined
                            dense
                          ></v-text-field> -->

                          <v-select
                            :disabled="$store.getters['user/mealPlans'] == 0"
                            :hint="
                              data.physicalActivityLevel
                                ? data.physicalActivityLevel.description
                                : ''
                            "
                            :persistent-hint="!!data.physicalActivityLevel"
                            v-model="data.physicalActivityLevel"
                            label="Average Daily Physical Activity Level"
                            :rules="[rules.required]"
                            item-text="name"
                            :items="levels"
                            return-object
                            outlined
                            dense
                          ></v-select>
                        </v-col>

                        <!-- <v-col cols="4">
                          <v-card class="mb-3"
                            :style="{
                              backgroundColor: user?.brandColor
                                ? user?.brandColor
                                : '#97c81f',
                            }"
                          >
                            <v-card-text class="white--text">
                              <div class="font-weight-bold mb-3">
                                BMI Categories:
                              </div>
                              Underweight =  &lt; 18.5 <br>
                              Normal weight = 18.5 - 24.9 <br>
                              Overweight = 25 - 29.9 <br>
                              Obesity = BMI of 30 or greater <br>
                            </v-card-text>
                          </v-card>

                          <v-card class="d-none" color="success">
                            <v-card-text class="white--text">
                              <div class="font-weight-bold mb-3">
                                Ideal Body Weight
                              </div>
                              <div class="display-1 font-weight-bold">
                                {{ Math.round(idealWeight) }}kg
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-col> -->
                      </v-row>
                    </v-form>

                    <v-row justify="end">
                      <v-btn
                        @click="goToPageTwo()"
                        depressed
                        :disabled="$store.getters['user/mealPlans'] == 0"
                        class="white--text"
                        :style="{
                          backgroundColor: user?.brandColor
                            ? user?.brandColor
                            : '#97c81f',
                        }"
                      >
                        Continue
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <!-- TAGS -->
              <v-stepper-content step="2">
                <!-- <p class="mb-3 grey--text text--darken-1">
                  Please select any meal tags that suit your lifestyle?
                </p>
                <v-sheet max-width="590" color="transparent">
                  <v-select
                    :items="mealTags"
                    v-model="customModel.mealTags"
                    placeholder="Select meal tags"
                    class="meal-tags align-start"
                    v-if="mealTags.length"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    height="50"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-select>
                  <v-combobox
                    placeholder="Type the Meal Tags you would like to exclude then press enter"
                    v-model="customModel.mealTags"
                    class="meal-tags align-start"
                    deletable-chips
                    @change="($event) => filterMealTags($event)"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet> -->
                <!-- <p class="mb-3 grey--text text--darken-1">
                  Please select any nutrition tags or diet requirements you have? (use
                  this feature to exclude food groups)
                </p> -->
                <p class="mb-3 grey--text text--darken-1">
                  Please list any diet preferences i.e. low carb or gluten-free.
                </p>
                <v-sheet max-width="590" color="transparent">
                  <!-- <v-select
                    :items="nutritionTags"
                    v-model="customModel.nutritionTags"
                    placeholder="Select nutrition tags"
                    class="meal-tags align-start"
                    v-if="nutritionTags.length"
                    item-text="name"
                    item-value="id"
                    deletable-chips
                    height="50"
                    width="100%"
                    small-chips multiple
                    outlined
                    chips
                    dense
                  ></v-select> -->
                  <v-combobox
                    placeholder="PLEASE PRESS ENTER AFTER EACH DIETARY PREFERENCE BEFORE ADDING THE NEXT"
                    v-model="customModel.tags"
                    class="meal-tags align-start"
                    append-icon=""
                    deletable-chips
                    @change="($event) => filterNutriTags($event)"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet>
                <p class="mb-3 grey--text text--darken-1">
                  List any individual foods you wish to exclude i.e. cashews. To exclude
                  entire food groups such as nuts, please use the diet preferences box
                  above i.e. 'Nut Free'
                </p>
                <v-sheet max-width="590" color="transparent">
                  <!-- <v-autocomplete
                    placeholder="Type the ingredients you would like to exclude then press enter"
                    v-model="customModel.ingredientTags"
                    class="meal-tags align-start"
                    :items="foods"
                    deletable-chips
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                  ></v-autocomplete> -->
                  <v-combobox
                    :loading="loadingIngTags"
                    placeholder="PLEASE PRESS ENTER AFTER EACH FOOD BEFORE ADDING THE NEXT"
                    v-model="customModel.ingredientTags"
                    append-icon=""
                    class="meal-tags align-start"
                    deletable-chips
                    @change="($event) => filterIngredientTags($event)"
                    width="100%"
                    small-chips
                    multiple
                    outlined
                    chips
                    dense
                  ></v-combobox>
                </v-sheet>

                <v-row
                  justify="end"
                  class="pa-3"
                  v-if="
                    $store.getters['user/mealPlans'] > 0 ||
                    $store.getters['user/mealPlans'] == -1
                  "
                >
                  <v-btn @click="step = 1" :disable="adding" text class="mr-2"
                    >Back</v-btn
                  >

                  <v-btn
                    :loading="adding"
                    @click="validateForm()"
                    depressed
                    :disabled="loadingIngTags"
                    class="white--text mr-2"
                    :style="{
                      backgroundColor: user?.brandColor ? user?.brandColor : '#97c81f',
                    }"
                  >
                    Submit
                  </v-btn>
                </v-row>
                <v-row justify="end" class="pa-3" v-else>
                  <p class="text-caption red--text">
                    *This {{ determineRole(user.role) }} has reach his/her meal plans
                    limits
                  </p>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-text v-if="done">
          <v-alert text type="success">
            Success! Your form has been submitted to
            <strong>{{ user.firstName }} {{ user.lastName }}</strong
            >. Thank you!
          </v-alert>
          <div
            class="d-flex justify-center align-center flex-wrap"
            v-if="autoGen == 'true'"
          >
            <div class="col-12 pa-0 text-center">
              <div class="text-subtitle1">Generating your eBook. Please wait..</div>
            </div>
            <div class="col-12 text-center">
              <v-progress-circular indeterminate size="34"></v-progress-circular>
            </div>
          </div>
        </v-card-text>
      </div>
      <!-- <v-card-text 
        v-else
      >
        <v-alert
          text
          type="error"
          v-if="!gettingUserInfo"
        >
          This {{ determineRole(user.role) }} has reach his/her meal plans limits
        </v-alert>
        <div class="d-flex justify-center align-center flex-wrap" v-else>
          <div class="col-12 pa-0 text-center">
              <div class="text-subtitle1">Please wait..</div>
          </div>
          <div class="col-12 text-center">
              <v-progress-circular indeterminate size="34"></v-progress-circular>
          </div>
        </div>
      </v-card-text> -->
    </v-card>

    <v-dialog max-width="380" v-model="warningDialog" persistent>
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          <span class="font-weight-bold">NOTICE</span>
        </v-card-title>
        <v-card-text>
          <div v-if="warningMessage" class="ql-editor">
            <div v-html="warningMessage" />
          </div>
          <div v-else>
            You have recently created a meal plan. You can try again after <strong>{{ targetDate }}</strong>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import firebase from 'firebase'
import rules from "@/rules";
import { mapState, mapGetters, mapActions } from "vuex";
import { goals, frequencies, levels, patterns, stress } from "@/options";
import { startCase, orderBy } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { nutriTagsKeywords } from "@/options"

import algoliasearch from 'algoliasearch'
const client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, 'b01a34dc50c42c2e6fe90f9ec6d376ad')
const index = client.initIndex('foods')

let getFunc = firebase.functions().httpsCallable('sendEmail')


export default {
  name: "PromoForm",

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    plan: Object,
    add: {
      type: Boolean,
      default: true,
    },
    editNewMealplan: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      goals,
      rules,
      levels,
      stress,
      orderBy,
      step: 1,
      patterns,
      startCase,
      done: false,
      frequencies,
      results: [],
      adding: false,
      loading: false,
      targetDate: null,
      oldGoals: [
        { text: "My goal is to lose weight", value: "g1" },
        { text: "My goal is to reduce my blood glucose levels", value: "g2" },
        { text: "My goal is to manage my IBS", value: "g3" },
        { text: "My goal is to increase my fibre", value: "g4" },
        {
          text: "My goal is to eat appropriately after weight loss surgery",
          value: "g5",
        },
        { text: "My goal is to improve my heart health", value: "g6" },
        { text: "My goal is to manage my allergies/intolerance", value: "g7" },
        { text: "My goal is to gain weight", value: "g8" },
        { text: "My goal is to maintain my weight", value: "g9" },
      ],
      customModel: {
        tags: [],
        ingredientTags: []
      },
      currentClient: null,
      existingClient: false,
      // gettingUserInfo: true,
      loadingIngTags: false,
      // OPTIMIZE
      nutriTagsKeywords,
      warningMessage: "",
      warningDialog: false,
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: (state) => state.goal.data,
      goal: (state) => state.goal.goal,
      user: (state) => state.user.user,
      status: (state) => state.goal.status,
      foods: (state) => state.foods.foodNames,
      plans: (state) => state.mealplans.plans,
      folders: (state) => state.mealplans.folders,
      mealplanData: (state) => state.mealplans.data,
      mealplanStatus: (state) => state.mealplans.status,
      currentFolder: (state) => state.mealplans.currentFolder,
    }),

    nutritionTags: function () {
      return this.$store.getters["nutritiontags/tags"];
    },

    mealTags: function () {
      return this.$store.getters["tags/tags"];
    },

    ...mapGetters("goal", [
      "customDailyEnergyRequirementLose",
      "dailyEnergyRequirementLose",
      "dailyEnergyRequirementCal",
      "dailyEnergyRequirement",
      "weightLossEnergyTarget",
      "energyRequirementsLose",
      "mainMealsEnergy",
      "energyTarget",
      "snacksEnergy",
      "idealWeight",
      "adjusted",
      "eermwCal",
      "eerlwCal",
      "eermwKj",
      "eerlwKj",
      "sctbg",
      "sktbg",
      "bmi",
    ]),

    dailyEnergyRequirementComp: function () {
      return this.dailyEnergyRequirement;
    },

    pagePromoData: function () {
      return this.$route.query.promopage;
    },

    autoGen: function () {
      return this.$route.query.autogen;
    },
    mealTagNames: function () {
      return this.mealTags.map((tag) => tag.name.toLowerCase());
    },
    nutritionTagsNames: function () {
      return this.nutritionTags.map((tag) => tag.name.toLowerCase());
    },
    mealPlans() {
      return this.plans.filter((plan) => !plan?.promo);
    },

    recipePlans() {
      return this.plans.filter((plan) => plan?.promo);
    },
    clients() {
      if (!this.folders.length) return [];

      if (!this.plans.length) return [];

      return this.folders.map((folder) => {
        let mealplans = this.plans.filter((p) => p.folderId == folder?.id);

        let mealplan = mealplans[mealplans.length - 1];

        let myMealPlans = this.mealPlans.filter((m) => m.folderId == folder?.id);

        let myRecipePlans = this.recipePlans.filter((m) => m.folderId == folder?.id);

        let mealPattern = [];

        if (folder?.mainMeals) mealPattern = [...folder?.mainMeals];

        if (folder?.snacks) {
          for (let s = 1; s <= folder?.snacks; s++) {
            mealPattern.push(`Snack ${s}`);
          }
        }

        return {
          view: true,
          name: folder.client,
          email: folder.email,
          folderId: folder.id,
          age: folder.age,
          goal: this.getGoalValue(folder.goal).text,
          bmi: Math.round(
            folder.weight / ((folder.height / 100) * (folder.height / 100))
          ),
          pal: folder.physicalActivityLevel.name,
          mealPattern,
          mealPlans: myMealPlans.length,
          recipePlans: myRecipePlans.length,
          lastCreated: moment(mealplan.createdAt).format("MMM DD YYYY"),
        };
      });
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    goal: function (value) {
      if (value) {
        this.$store.commit("goal/setData", value);
      }
    },

    // 'data': function (value) {
    //   if (value) {
    //     console.log(value)
    //     this.$store.commit('goal/setData', value)
    //   }
    // },

    sktbg: {
      handler(value) {
        if (value < 5000) this.data.customTarget = 5000;
        else this.data.customTarget = 0;
      },
      immediate: true,
    },

    "data.customTarget": function (val) {
      if (val) this.data.customTarget = Math.round(val);
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions("goal", ["deleteCustomTarget", "getGoal", "create", "update"]),

    ...mapActions("mealplans", [
      "autoGenerateFromForm",
      "updateFolderFromForm",
      "addPlanToFolderForm",
      "getFoldersFromForm",
      "addFolderFromForm",
      "getPlansFromForm",
      "addGeneratedLink",
      "searchFolder",
    ]),

    ...mapActions("user", ["getUserInfo"]),

    ...mapActions("foods", ["getFoodsForForm"]),

    ...mapActions("subscriptions", ["getPayments", "getSubscriptions"]),

    goToPageTwo() {
      if (this.existingClient)
        this.$store.commit("goal/setAddNewMealplan", {
          mainMeals: [],
          snacks: 0,
          customTarget: 0,
        });

      if (this.$refs.goalForm.validate()) {
        this.step = 2;
      }
    },

    goToPageThree() {
      if (this.$refs.parameterForm.validate()) {
        this.step = 3;
      }
    },

    gotoPageFour() {
      if (this.$refs.clinicalForm.validate()) {
        this.step = 4;
      }
    },

    validateForm() {
      this.adding = true;
      let task, promoData, pageAd1Data;

      if (this.pagePromoData == "1") {
        promoData = this.user.promoData || {};
        pageAd1Data = this.user.pageAd1Data || {};
      } else if (this.pagePromoData == "2") {
        promoData = this.user.promoData2 || {};
        pageAd1Data = this.user.pageAd2Data || {};
      } else if (this.pagePromoData == "3") {
        promoData = this.user.promoData3 || {};
        pageAd1Data = this.user.pageAd3Data || {};
      } else {
        promoData = {};
        pageAd1Data = {};
      }


      this.data.mainMeals = ["breakfast", "lunch", "dinner"];
      this.data.snacks = 2;

      if (this.existingClient)
        task = Promise.resolve(
          this.updateFolderFromForm({
            id: this.currentClient.id,
            ref: this.currentClient.ref,
            promo: true,
            ...this.data,
          })
        );
      else
        task = Promise.resolve(
          this.addFolderFromForm({ user: this.user, ...this.data, promo: true })
        );

      task
        .then(({ newData: taskData }) => {
          let mealplan, name;

          let goalData = this.existingClient ? this.data : taskData;

          const details = {
            user: {
              id: this.user.id,
              role: this.user.role,
              lastName: this.user.lastName,
              firstName: this.user.firstName,
              brandColor: this.user.brandColor,
              energyUnit: this.user.energyUnit,
              isStudent: this.user?.isStudent ? true : false,
              customLogoUrl: this.user.customLogo ? this.user.customLogoUrl : "",
              promoData,
              pageAd1Data,
            },
            recipes: [],
            mainMeals: [],
            meals: [],
            goal: goalData,
          };

          if (this.existingClient) {
            name = `${this.data.client} ${this.data.mealplans.length + 1}`;
            mealplan = Promise.resolve(
              this.$store.dispatch("mealplans/createFromForm", {
                autogen: this.autoGen == "true" ? true : false,
                id: this.user.id,
                folderId: this.currentClient.id,
                linkDetails: details,
                recipeplan: true,
                promo: true,
                name,
              })
            );
          } else {
            name = `${this.data.client} 1`;

            mealplan = Promise.resolve(
              this.$store.dispatch("mealplans/createFromForm", {
                autogen: this.autoGen == "true" ? true : false,
                id: this.user.id,
                folderId: taskData.id,
                linkDetails: details,
                recipeplan: true,
                promo: true,
                name,
              })
            );
          }

          /**
           * TODO: Find a way to optimize this
           */
          mealplan
            .then(({ error, newData, data }) => {
              if (!error) {
                setTimeout(() => {
                  Promise.resolve(
                    this.$store.dispatch("goal/createFromForm", {
                      id: newData.id,
                      user: this.user,
                    })
                  ).then(async () => {
                    let model = { ...this.customModel };

                    let nTags = this.customModel.tags.filter((t) =>
                      this.nutritionTagsNames.includes(t.toLowerCase())
                    );

                    let mTags = this.customModel.tags.filter((t) =>
                      this.mealTagNames.includes(t.toLowerCase())
                    );

                    model.nutritionTags = nTags.map((tag) => {
                      return this.nutritionTags.find((t) => t.name.toLowerCase() == tag.toLowerCase()).id;
                    });

                    model.mealTags = mTags.map((tag) => {
                      return this.mealTags.find((t) => t.name.toLowerCase() == tag.toLowerCase()).id;
                    });

                    this.$store.dispatch("saveDocument", {
                      ...newData,
                      ...model,
                    });

                    if(model?.ingredientTags?.length) {
                       const tags = await this.getIngredientTags();
                       model.ingredientTags = tags
                    }

                    setTimeout(() => {
                      if (this.existingClient) {
                        this.addPlanToFolderForm({
                          id: newData.id,
                          folder: this.currentClient,
                        });
                      } else {
                        this.addPlanToFolderForm({ id: newData.id, folder: taskData });
                      }

                      setTimeout(() => {
                        Promise.resolve(
                          this.autoGenerateFromForm({
                            plan: { ...newData, ...data, ...model },
                            roleType: "all",
                            user: this.user,
                          })
                        ).then(() => {
                          setTimeout(() => {
                            let emailData = {
                              recipient: this.user.email,
                              subject: "Meal Plan Submission",
                              message: `Hi ${this.user.firstName} ${this.user.lastName}, <br/> <br/> ${this.data.client} is requesting a new meal plan via a new submission of the Meal Plan Questionnaire. <br/><br/>
                          If they are a new client, a new folder would have been created with their meal plan within that folder. <br/><br/>
                          If they are an existing client, and the email address they submitted matches the one in your profile, then a new meal plan will have been added to their existing folder. <br/><br/>
                          If they are an existing client however, no folder will exist for them due to being added to the system before the creation of the folder system. Instead, you will find their meal plan in the 'Others' folder. <br/> <br/>
                          Feel free to reach, email us at <a href="mailto:hello@mealzee.com" style="text-decoration: underline">hello@mealzee.com</a> if you have any question. <br/> <br/>
                          Regards<br/> <br/>

                          Chris Hughes - Dietitian & Founder of MEALZEE
                          `,
                            };
                            // const link = `http://mealguide.pro/ebook/${user.firstName}_${user.lastName}/${plan.name.replace(/[^\w\s]/ig, "")}?i=${plan.id}`

                            const link = `https://mealguide.pro/promoebookpage/${this.removeSpace(
                              `${this.user.firstName}${this.user.lastName}`
                            )}/${this.removeSpace(data.name.replace(/#/gi, ""))}?i=${
                              newData.id
                            }`;

                            // const link = `${
                            //   window.origin
                            // }/promoebookpage/${this.removeSpace(
                            //   `${this.user.firstName}${this.user.lastName}`
                            // )}/${this.removeSpace(data.name.replace(/#/gi, ""))}?i=${
                            //   newData.id
                            // }`;

                            // let emailLink = {
                            //   recipient: this.data.email,
                            //   subject: "Thank you!",
                            //   message: `Hi ${this.data.client}, <br/> <br/> Heres the link for your ebook ${link}`,
                            // };

                            // this.$store.dispatch("mail/notify", emailLink);

                            this.sendEmail(`${this.user.firstName} ${this.user.lastName}`,this.data.client, this.data.email, link );

                            this.adding = false;
                            this.done = true;
                            this.$store.dispatch("mealplans/updateMealField", {
                              value: true,
                              field: "mealsLoaded",
                              meal: newData,
                              silent: true,
                            });

                            if(this.user?.mealSlots > 0) {
                              this.$store.dispatch('user/updateUserFieldFromForm', {
                                field: 'mealSlots',
                                value: this.user?.mealSlots - 1,
                                silent: true,
                                user: this.user
                              });
                            }

                            if (this.autoGen == "true") {
                              this.$store.dispatch("mail/notify", emailData);
                              setTimeout(() => {
                                window.location.href = link;
                              }, 4500);
                            }
                          }, 200);
                        });
                      }, 400);
                    }, 800);
                  });
                }, 800);
              } else {
                console.log(error);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getIngredientTags() {
      return new Promise((resolve) => {
        let allIngredientTags = [...this.customModel.ingredientTags]
        this.customModel.ingredientTags.forEach(async (tag, i, array) => {
          /*
          * TODO: ADD A CHECK TO FOR ERRORS
          */

          const { hits } = await index.search(tag)

          if(hits.length) 
            hits.forEach(hit => {
              allIngredientTags.push(hit.name)
            })

          if(i == array.length - 1 ) resolve(allIngredientTags)
        })

      })
    },

    setGoal() {
      if (this.editNewMealplan) this.getGoal(this.plan ? this.plan.id : null);
    },

    searchClient(e) {
      this.loading = true;

      let promoData;

      switch (this.pagePromoData) {
        case "1":
          promoData = this.user.promoData;
          break;
        case "2":
          promoData = this.user.promoData2;
          break;
        case "3":
          promoData = this.user.promoData3;
          break;
        default:
          promoData = {};
          break;
      }

      if(promoData?.intervalMessage) 
        this.warningMessage = promoData?.intervalMessage
      else
        this.warningMessage = ""

      Promise.resolve(this.searchFolder({ email: e.target.value, user: this.user }))
        .then((res) => {
          if (res) {
            this.$store.dispatch("showSuccess", "Client info found");

            if (promoData?.dateNumber) {
              let recipeplans;

              recipeplans = this.recipePlans.filter((p) => p.folderId == res?.id);

              recipeplans = orderBy(recipeplans, "createdAt", "desc");

              let recipeplan = recipeplans[0];

              let targetDate = moment(recipeplan.createdAt)
                .add(
                  promoData?.dateNumber,
                  pluralize(promoData?.dateName, promoData?.dateNumber)
                )
                .format("MMM DD YYYY");
              
              this.targetDate = targetDate

              let hasReach = moment().isAfter(targetDate);

              if (hasReach) {
                this.$store.commit("goal/setGoalData", res);
                this.existingClient = true;
                this.currentClient = res;
              } else {
                this.warningDialog = true;
              }
            } else {
              this.$store.commit("goal/setGoalData", res);
              this.existingClient = true;
              this.currentClient = res;
            }
          } else {
            if (this.data.client) {
              this.$store.commit("goal/setGoalData", { mainMeals: [] });
              this.currentClient = null;
            }

            this.existingClient = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    determineRole(role) {
      return role === "personal_trainer"
        ? "Health & Fitness Professional"
        : startCase(role);
    },

    filterIngredientTags (val) {
      let result

      let re = new RegExp(val[val.length - 1], 'gi')
      result = this.foods.some(food => re.test(food))

      if(!result) {
        this.$store.dispatch('showError', 'Sorry, selected ingredient is not found in our database')

        this.customModel.ingredientTags.pop()
      }
    },

    // filterIngredientTags(val) {
    //   let result;

    //   console.log(val)

    //   let re = new RegExp(val[val.length - 1], "gi");
    //   result = this.foods.some((food) => re.test(food));

    //   if (!result) {
    //     this.$store.dispatch(
    //       "showError",
    //       "Sorry, selected ingredient is not found in our database"
    //     );

    //     this.customModel.ingredientTags.pop();
    //   } else {
    //     this.loadingIngTags = true
    //     index.search(val).then(({ hits }) => {
    //       console.log(hits)
    //       if(!hits.length) {
    //           this.$store.dispatch('showError', 'Food not found')
    //       } else {
    //         let promises = []
    //         let otherIngredientTags = []

    //         for (let hit of hits) {
    //           promises.push(db.collection('foods').doc(hit.objectID).get())
    //         }

    //         Promise.all(promises)
    //         .then(docs => {
    //           docs.forEach(doc => {
    //             if (doc.exists) {
    //               let food = doc.data()
    //               food.id = doc.id
    //               food.ref = doc.ref
    //               if (!this.results.find(r => r.id == food.id))
    //                 this.results.push(food)
    //             }

    //           })

    //           this.results.forEach(r => {
    //             otherIngredientTags.push(r.name)
    //           })

    //           this.customModel.ingredientTagsGroups.push({
    //             name: val,
    //             ingredients: otherIngredientTags
    //           })

    //           this.loadingIngTags = false
    //         })
    //       }
    //     })
    //   }
    // },

    // filterMealTags(val) {
    //   if(!val.length) return

    //   let result;
    //   let BreakException = {}

    //   let re = new RegExp(val[val.length - 1], "gi")

    //   this.mealTagsKeywords.forEach(tag => {
    //     result = tag.keywords.some((k) => re.test(k))

    //     if(result) {

    //      this.customModel.mealTags.pop();

    //      let exist = this.customModel.mealTags.some(t => t == tag.name)

    //      if(exist) {
    //         this.$store.dispatch(
    //           "showSuccess",
    //           `The tag is part of the ${tag.name} meal tag`
    //         );
    //      } else {
    //       this.customModel.mealTags.push(tag.name)
    //      }

    //      throw BreakException

    //     }
    //   })

    //   if (!result && !this.mealTagNames.includes(val[val.length - 1])) {
    //     this.$store.dispatch(
    //       "showError",
    //       "Sorry, selected meal tag is not available"
    //     );

    //     this.customModel.mealTags.pop();
    //   }
    // },

    filterNutriTags(val) {
      if (!val.length) return;

      let result;
      let BreakException = {};

      // let re = new RegExp("\\b" + val[val.length - 1] +  "\\b", "i")
      let re = new RegExp(val[val.length - 1], "gi");

      this.nutriTagsKeywords.forEach((tag) => {
        result = tag.keywords.some((k) => re.test(k));

        if (result) {
          this.customModel.tags.pop();

          let exist = this.customModel.tags.some((t) => t == tag.name);

          if (exist) {
            this.$store.dispatch("showSuccess", "Tag is already in used.");
          } else {
            this.customModel.tags.push(tag.name);
          }

          throw BreakException;
        }
      });

      if (!result && !this.nutriTagsKeywords.some((t) => t.name == val[val.length - 1])) {
        this.$store.dispatch("showError", "Sorry, the selected tag is not available");

        this.customModel.tags.pop();
      }
    },

    removeSpace(text) {
      return text.replace(/\s/g, "%20");
    },

    getGoalValue(val) {
      return this.oldGoals.find((g) => g.value == val);
    },

    sendEmail(dietitian, client, email, link) {
      getFunc({ dietitian, client, email, link })
      // console.log('sending')
      // getFunc({ dietitian: 'Liuk Jhivran', email: 'lexzstulawie@gmail.com', client: 'Lexz Tulawie', link: ''})
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    Promise.resolve(this.getUserInfo(this.$route.query.i)).then(async () => {
      await Promise.resolve(this.getPayments(this.user.id));

      await Promise.resolve(this.getSubscriptions(this.user.id));

      await Promise.resolve(this.getFoldersFromForm(this.user));

      await Promise.resolve(this.getPlansFromForm(this.user));

      // this.gettingUserInfo = false;

      if (this.pagePromoData == "1") {
        if(this.user?.promoData?.tags?.length)
          this.customModel.tags = this.user.promoData?.tags
      } else if (this.pagePromoData == "2") {
        if(this.user?.promoData2?.tags?.length)
          this.customModel.tags = this.user.promoData2?.tags
      } 
      else {
        if(this.user?.promoData3?.tags?.length)
          this.customModel.tags = this.user.promoData3?.tags
      }
    });
    if (!this.$store.state.tags.status.firstLoad) {
      this.$store.dispatch("tags/getTags");
    }
    this.$store.dispatch("nutritiontags/getTags");
    this.getFoodsForForm(this.user);
  },
};
</script>
